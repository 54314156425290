<template lang="pug">
IonButton(
    class="check-button"
    :color="color"
    size="large"
    style="height: 100%"
)
    div(
        class="d-flex justify-start align-center"
        style="padding: 6px 0"
    )
        CustomCheckableIcon(
            :checked="checked"
            style="flex-shrink: 0"
            :fileName="fileName"
        )
        IonText(
            color="white"
            :class="reversed ? 'order-1 mr-2' : 'ml-2'"
            style="flex-grow: 1"
        )
            h3(
                style="text-align: left"
            )
                slot
</template>

<script>
import CustomCheckableIcon from "@/components/molecules/CustomCheckableIcon"
export default {
    components: { CustomCheckableIcon },

    props: {
        reversed: {
            type: Boolean
        },
        checked: {
            type: Boolean
        },
        reversed: {
            type: Boolean
        },
        color: {
            type: String,
            default: "secondary"
        },
        fileName: {
            type: String
        }
    }
}
</script>
