<template lang="pug">
div(class="d-flex")
    img(
        src="@/assets/icon/dotted-arrow-1.png"
        height="35"
        class="mr-4 mt-2"
    )
    div
        IonText(
            color="secondary"
        )
            h1 {{ order.id }} {{ order.cause }}
        IonText(
            color="tertiary"
        )
            //- div(class="mt-1") geplante Uhrzeit: 11:30 Uhr
            div(class="mt-1") geplante Uhrzeit: {{ startTimeToDisplay }} Uhr
</template>

<script>
import moment from "moment"
import { computed } from "vue"
export default {
    props: {
        order: {
            type: Object,
            required: true
        }
    },

    setup(props) {
        const startTimeToDisplay = computed(() => {
            let formatted = moment(
                props.order.starttime,
                "YYYY-MM-DDTHH:mm:ss"
            ).format("HH:mm")
            return formatted
        })
        return { startTimeToDisplay }
    }
}
</script>