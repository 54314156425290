<template lang="pug">
BaseLayout
    Step5(
        :order="currentOrder"
    )
</template>

<script>
import Step5 from "@/components/sections/order-processing/Step5"
import { useUtilities } from "@/composables/utilities"

export default {
    components: {
        Step5
    },

    setup() {
        const { currentOrder } = useUtilities()
        return { currentOrder }
    }
}
</script>