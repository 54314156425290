<template lang="pug">
div(
    v-if="!!order"
)
    //- name bar
    TopBar
        OrderName(
            :order="order"
        )

    //- stopwatch
    Stopwatch(
        ref="stopwatch"
        v-model="stopwatchModel"
        @stopwatch-started="stopwatchIsCounting = true"
        @stopwatch-paused="stopwatchIsCounting = false"
    )

    //- jobs
    OrderLines(
        :order="order"
    )
        //- :readonly="!!stopwatchIsCounting"

    //- buttons
    div(class="buttons-height-2 mt-5")
        //- div(class="page-buttons-wrapper")
        //-     Container
        //-         IonRow(class="receding")
        //-             IonCol(
        //-                 size="6"
        //-                 class="pb-0"
        //-             )
        //-                 IonButton(
        //-                     color="secondary"
        //-                     size="large"
        //-                     expand="block"
        //-                 )
        //-                     IonText(
        //-                         color="primary"
        //-                     )
        //-                         h3 Verspätet

        //-             IonCol(
        //-                 size="6"
        //-             )
        //-                 IonButton(
        //-                     color="secondary"
        //-                     size="large"
        //-                     expand="block"
        //-                     @click="toggleStopwatch"
        //-                 )
        //-                     IonText(
        //-                         color="primary"
        //-                     )
        //-                         h3
        //-                             span(
        //-                                 v-if="!!stopwatchIsCounting"
        //-                             ) Pause
        //-                             span(
        //-                                 v-else
        //-                             ) Start

        //-             IonCol(
        //-                 size="6"
        //-                 class="pb-0"
        //-             )
        //-                 IonButton(
        //-                     color="secondary"
        //-                     size="large"
        //-                     expand="block"
        //-                 )
        //-                     IonText(
        //-                         color="primary"
        //-                     )
        //-                         h3 Abbrechen

        //-             IonCol(
        //-                 size="6"
        //-             )
        //-                 IonButton(
        //-                     color="secondary"
        //-                     size="large"
        //-                     expand="block"
        //-                     @click="$router.push({ name: 'order-6', params: { id: order.id } })"
        //-                 )
        //-                     IonText(
        //-                         color="primary"
        //-                     )
        //-                         h3 Ende
</template>

<script>
import TopBar from "@/components/sections/TopBar"
import OrderName from "@/components/molecules/OrderName"
import CheckableButton from "@/components/molecules/CheckableButton"
import CustomRow from "@/components/molecules/CustomRow"
import Box from "@/components/molecules/Box"
import OrderLines from "@/components/molecules/OrderLines"
import Stopwatch from "@/components/molecules/Stopwatch"
import { useBtnController } from "@/composables/buttons-controller"
import { useUtilities } from "@/composables/utilities"

import { ref, watch, onMounted } from "vue"
export default {
    components: {
        TopBar,
        OrderName,
        CheckableButton,
        Box,
        CustomRow,
        OrderLines,
        Stopwatch
    },

    props: {
        order: {
            required: true
        }
    },

    setup() {
        const stopwatch = ref()

        const stopwatchModel = ref(0)

        const stopwatchIsCounting = ref()

        onMounted(() => {
            stopwatch.value.start()
        })

        // function toggleStopwatch() {
        //     !!stopwatchIsCounting.value
        //         ? stopwatch.value.pause()
        //         : stopwatch.value.start()
        // }

        // bus
        const emitter = useUtilities().getEmitter()
        emitter.on("setStopwatch", (val) => {
            if (val) stopwatch.value.start()
            else stopwatch.value.pause()
        })

        const { setStopwatchIsCountingBtn } = useBtnController()
        watch(
            () => stopwatchIsCounting.value,
            (val) => setStopwatchIsCountingBtn(val),
            { immediate: true }
        )

        return {
            stopwatch,
            stopwatchModel,
            stopwatchIsCounting
        }
    }
}
</script>