<template lang="pug">
div(
    style="background-color: var(--ion-color-accent); height: 300px"
    class="d-flex align-center justify-center"
)
    IonText(
        :color="!isCounting ? 'secondary' : 'white'"
    )
        h1(
            style="font-size: 50px"
        ) {{ formattedElapsedTime }}
</template>

<script>
import { ref, computed, watch } from "vue"
export default {
    props: ["modelValue"],

    setup(props, { emit }) {
        const elapsedTime = ref(0)
        const timer = ref(undefined)

        const formattedElapsedTime = computed(() => {
            const date = new Date(null)
            date.setSeconds(elapsedTime.value / 1000)
            const utc = date.toUTCString()
            return utc.substr(utc.indexOf(":") - 2, 8)
        })

        const isCounting = ref()

        watch(elapsedTime, (val) => {
            emit(`update:modelValue`, val)
        })

        watch(
            () => props.modelValue,
            (val) => {
                elapsedTime.value = val
            },
            { immediate: true }
        )

        function start() {
            timer.value = setInterval(() => {
                elapsedTime.value += 1000
            }, 1000)
            emit("stopwatch-started")
            isCounting.value = true
        }

        function pause() {
            clearInterval(timer.value)
            emit("stopwatch-paused")
            isCounting.value = false
        }

        function reset() {
            elapsedTime.value = 0
            emit("stopwatch-reseted")
        }

        return {
            elapsedTime,
            timer,
            formattedElapsedTime,
            start,
            pause,
            reset,
            isCounting
        }
    }
}
</script>