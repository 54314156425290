<template lang="pug">
IonRow(
    style="width: 100%"
    class="custom-row"
)
    IonCol(
        :size="textColSize"
        class="text-col pt-3 pb-3 overflow-hidden d-flex align-center"
        :class="reversed ? 'radius-left order-1' : 'radius-right'"
        :style="computedTextColStyle"
        style="min-height: 65px"
    )
        slot(
            name="text"
        )

    IonCol(
        :size="buttonColSize"
        class="button-col overflow-hidden justify-center ion-no-padding d-flex align-center justify-center"
        :class="reversed ? 'radius-right' : 'radius-left'"
    )
        slot(
            name="button"
        )
</template>

<script>
import { computed } from "vue"
import { chevronForwardCircle } from "ionicons/icons"
export default {
    props: {
        reversed: {
            type: Boolean
        },
        textColSize: {
            type: String,
            default: "10"
        },
        buttonColSize: {
            type: String,
            default: "2"
        },
        textColBackgroundColor: {
            type: String
        }
    },

    setup(props) {
        const computedTextColStyle = computed(() => {
            return `
                background-color: ${props.textColBackgroundColor}
            `
        })
        chevronForwardCircle
        return { computedTextColStyle, chevronForwardCircle }
    }
}
</script>

<style lang="sass">
.custom-row
    .button-col
        ion-button
            margin: 0

</style>